import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationExtras, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private _router: Router,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> | Promise<UrlTree | boolean> | UrlTree | boolean {
        const token = sessionStorage.getItem('token');

        if (!token) {
            const returnUrl: NavigationExtras = {
                queryParams: {
                    returnUrl: (state.url === '/') ? '/platform/user-apps' : state.url
                }
            };

            this._router.navigate(['/home'], returnUrl);

            return false;
        }

        return true;
    }

}
