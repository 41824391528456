/* istanbul ignore file */

export interface Guardrail {
    emptyStateMessage: EmptyStateMessage;
    loading: boolean;
    requiredDataExists: boolean;
}

export class AdminEmptyStateMessageDetails {
    contentDescriptor: string;
    requiredDataDescriptor: string;
    requiredDataDescriptorPlural: string;
    suggestedActionDescriptor: string;
}

function isTypeAdminEmptyStateMessageDetails(messageData: AdminEmptyStateMessageDetails | EmptyStateMessage): boolean {
    return (messageData as AdminEmptyStateMessageDetails).requiredDataDescriptor !== undefined;
}

export class EmptyStateMessage {
    header = '';
    bodyLinesInnerHtml: string[] = [];

    constructor(messageData: AdminEmptyStateMessageDetails | EmptyStateMessage) {

        if (isTypeAdminEmptyStateMessageDetails(messageData)) {
            messageData = messageData as AdminEmptyStateMessageDetails;
            this.header = `No ${messageData.requiredDataDescriptorPlural} created yet`;
            this.bodyLinesInnerHtml[0] = `You cannot use ${messageData.contentDescriptor} without having created a ${messageData.requiredDataDescriptor} first.`;
        }
        else {
            messageData = messageData as EmptyStateMessage;
            this.header = messageData.header;
            this.bodyLinesInnerHtml = messageData.bodyLinesInnerHtml;
        }
    }
}

