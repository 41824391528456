<div class="kh-error-page-container">
    <div class="kh-error-page-logo-container">
        <img
            class="kh-error-page-logo"
            alt="KH Smarts Logo"
            src="assets/logo-color-primary.png"
        />
    </div>
    <h1>Error 404: Page not found</h1>
</div>
