<h1
    mat-dialog-title
    class="khts-mat-dialog-title"
>
    Potential data overwrite
</h1>

<div
    mat-dialog-content
    class="khts-mat-dialog-content"
>
    <p class="kh-text">
        The following {{ overriddenSites.length }} {{ siteVsSites }} from your shapefile already exist in your SMARTS
        app. Uploading this data will overwrite existing details for {{ theseVsThis }} {{ siteVsSites }}, including
        {{ siteAliasLowerCase }} name, geojson, and charge number.
    </p>
    <p class="kh-text kh-bold-text">How would you like to proceed?</p>

    <kendo-grid
        [data]="dataSource"
        [height]="200"
        class="kh-grid-header-left-align kh-duplicate-sites-grid"
    >
        <kendo-grid-column
            field="siteNo"
            [title]="siteNumberTitle"
        ></kendo-grid-column>
    </kendo-grid>
</div>

<div
    mat-dialog-actions
    class="khts-mat-dialog-actions"
    align="end"
>
    <app-secondary-button
        (onClick)="closeDialog()"
        mat-dialog-close
    >
        Cancel
    </app-secondary-button>

    <app-flat-button (onClick)="onConfirm()"> Continue upload </app-flat-button>
</div>
