import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { App, Project, SiteFormGridRowItem, SitesLogicServiceModel } from '../models';
import { Site } from '../models/site';
import { ClientErrorStatusCodes, ErrorMessages, LicenseTiers } from '../shared/enums';
import { SitesDataService } from './data-services';
import { DateConversionService } from './utility-services';

@Injectable({
    providedIn: 'root',
})
export class SitesService {
    private _sitesReady: Subject<boolean> = new Subject<boolean>();
    private _siteReady: Subject<boolean> = new Subject<boolean>();
    private _activeSitesCountReady: Subject<boolean> = new Subject<boolean>();

    activeSitesCountReady$ = this._activeSitesCountReady.asObservable();
    sitesReady$ = this._sitesReady.asObservable();
    siteReady$ = this._siteReady.asObservable();

    siteLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    refreshSites$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    siteSuccessfullyUpdated$: Subject<boolean> = new Subject<boolean>();

    model: SitesLogicServiceModel = new SitesLogicServiceModel();

    dateRegexPattern = /^\d{4}-\d{2}-\d{2}$/;
    dateRegexPatternWithTime = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

    constructor(
        private _snackBar: MatSnackBar,
        private _sitesDataService: SitesDataService,
        private _dateConversionService: DateConversionService
    ) {
        const siteInSession = sessionStorage.getItem('site');
        if (siteInSession) {
            this.setSite(JSON.parse(siteInSession));
        }
    }

    updateSiteLocally(updatedSite: Site): void {
        const indexToUpdate: number = this.model.sites.findIndex(site => site.id == updatedSite.id);
        this.model.sites[indexToUpdate] = updatedSite;
        this.setSites(this.model.sites);
        this.setSite(updatedSite);
    }

    createSite(app: App, project: Project, site: Site): void {
        if (this.model.isSaving) return;
        this.model.isSaving = true;
        this._sitesDataService
            .createSite(app, project, site)
            .subscribe({
                next: (site: Site) => {
                    this.UpdateSiteLocally(site);
                    this._snackBar.open(`${app.aliases.site.singular} successfully created`, 'Close', { duration: 5000 });
                    this.model.isSaving = false;
                    if (app.activeLicenseTier.id !== LicenseTiers.PerSite)
                        this.getActiveSitesCountByAppId(app);
                    this._sitesReady.next(true);
                },
                error: () => {
                    this.model.isSaving = false;
                    this._snackBar.open(`There was an error creating the ${app.aliases.site.singular.toLocaleLowerCase()} record`, 'Close', { duration: 5000 });
                }
            });
    }

    updateSite(app: App, project: Project, site: Site): void {
        this.model.isSaving = true;
        this._sitesDataService.updateSite(app, project, site)
            .subscribe({
                next: (site: Site) => {
                    this.UpdateSiteLocally(site);
                    this._snackBar.open(`${app.aliases.site.singular} Saved`, 'Close', { duration: 5000 });
                    this.siteSuccessfullyUpdated$.next(true);
                    this._sitesReady.next(true);
                    this.model.isSaving = false;
                },
                error: () => {
                    this._snackBar.open(`There was an error updating the ${app.aliases.site.singular.toLocaleLowerCase()} record`, 'Close', { duration: 5000 });
                    this.model.isSaving = false;
                }
            });
    }

    isChargeNumberValid(site: Site): boolean {
        if (!site.chargeNumber || site.chargeNumber === '' || !site.isChargeNumberValid) {
            return false;
        }
        return true;
    }

    shouldDisableActivateSitePerSiteLicense(site: Site): boolean {
        if (site.active)
            return false;

        if ((site.renewCountDown || site.renewCountDown === 0) && site.renewCountDown > 0)
            return false;

        if ((site.renewCountDown || site.renewCountDown === 0) && site.renewCountDown <= 0)
            return true;

        if (this.isChargeNumberValid(site))
            return false;

        return true;
    }

    showMessageIfActivateSiteIsDisabled(site: Site, app: App): void {
        if (!this.shouldDisableActivateSitePerSiteLicense(site)) return;

        if ((site.renewCountDown || site.renewCountDown === 0) && site.renewCountDown <= 0) {
            this._snackBar.open(`To activate the ${app.aliases.site.singular.toLowerCase()}, please click the Renew button to the right.`, 'Close', { duration: 7000 });
            return;
        }

        this._snackBar.open(`To activate the ${app.aliases.site.singular.toLowerCase()}, please provide a Level 2 ${app.aliases.project.singular} Number.`, 'Close', { duration: 7000 });
    }

    changeShowSite(app: App, project: Project, site: Site): void {
        site.activeIsLoading = true;
        site.showSiteIsLoading = true;

        this._sitesDataService
            .updateSite(app, project, site)
            .subscribe({
                next: (site: Site) => {
                    this._snackBar.open(
                        `The ${app.aliases.site.singular.toLowerCase()} '${site.siteName}' is now '${site.showSite ? 'visible' : 'hidden'}' `,
                        'Close',
                        { duration: 5000 }
                    );
                },
                error: () => {
                    this._snackBar.open(`There was an error updating the ${app.aliases.site.singular.toLowerCase()} record`, 'Close', { duration: 5000 });
                },
                complete: () => { },
            })
            .add(() => {
                site.activeIsLoading = false;
                site.showSiteIsLoading = false;
            });
    }

    deleteSite(currentApp: App, site: Site): void {
        site.isSaving = true;

        this._sitesDataService.deleteSite(currentApp, site)
            .subscribe({
                next: () => {
                    this.model.sites = this.model.sites.filter((existingSite: Site) => existingSite.id !== site.id);
                    this.setSites(this.model.sites);
                    this._snackBar.open(`${currentApp.aliases.site.singular} deleted`, 'Close', { duration: 5000 });
                },
                error: () => {
                    this._snackBar.open(`There was an error deleting the ${currentApp.aliases.site.singular.toLowerCase()}`, 'Close', { duration: 5000 });
                },
                complete: () => { },
            })
            .add(() => {
                this.getActiveSitesCountByAppId(currentApp);
                site.isSaving = false;
            });
    }

    renewSiteSubscription(app: App, siteId: string): Observable<void> {
        const result$ = new Subject<void>();

        let currentSite = this.model.sites.find((site) => site.id === siteId);

        let siteToBeRenewed = new Site();

        Object.assign(siteToBeRenewed, currentSite);

        const isSiteBeingRenewedBeforeItExpires = siteToBeRenewed.renewCountDown > 0;

        siteToBeRenewed.active = true;
        siteToBeRenewed.renewSiteSubscription = true;

        const today = new Date();
        let newExpirationDate = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());

        if (isSiteBeingRenewedBeforeItExpires)
            newExpirationDate.setDate(newExpirationDate.getDate() + siteToBeRenewed.renewCountDown);

        siteToBeRenewed.renewDate = newExpirationDate.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" });
        siteToBeRenewed.renewCountDown = isSiteBeingRenewedBeforeItExpires ? siteToBeRenewed.renewCountDown + 365 : 365;

        this._sitesDataService
            .renewSiteSubscription(app, siteToBeRenewed)
            .subscribe({
                next: (site: Site) => {
                    this.UpdateSiteLocally(site);
                    result$.next();
                    result$.complete();
                    this._sitesReady.next(true);
                },
                error: () => {
                    this._snackBar.open(`There was an error renewing ${app.aliases.site.singular.toLocaleLowerCase()} subscription. Please try again or contact help desk.`, 'Close', { duration: 5000 });
                },
                complete: () => { },
            })
            .add(() => { });

        return result$.asObservable();
    }

    setSite(site: Site): void {
        sessionStorage.setItem('site', JSON.stringify(site));
        this.model.currentSite = site;
        this._siteReady.next(true);
    }

    clearSite(): void {
        sessionStorage.setItem('site', '');
        this.model.currentSite = new Site();
        this._siteReady.next(false);
    }

    getSite(): void {
        const site = JSON.parse(sessionStorage.getItem('site'));
        if (site) {
            this.setSite(site);
        }
    }

    setSites(sites: Site[]): void {
        this.model.sites = sites;
        this._sitesReady.next(true);
    }

    disconnectSite(): void {
        sessionStorage.removeItem('site');
        this.model.currentSite = new Site();
        this._siteReady.next(false);
    }

    disconnectSites(): void {
        this.model.sites = new Array<Site>();
    }

    getLatLongByAddress(site: Site): Observable<any> {
        const result$ = new Subject<any>();

        this._sitesDataService.getLatLongByAddress(site)
            .subscribe({
                next: (value) => {
                    result$.next(value);
                    result$.complete();
                },
                complete: () => { },
                error: () => { },
            })
            .add(() => { });

        return result$.asObservable();
    }

    UpdateSiteLocally(site: Site): void {
        if (this.model.sites.find((u) => u.id === site.id)) {
            let index = this.model.sites.findIndex((u) => u.id === site.id);
            this.model.sites[index] = site;
        }
        else {
            this.model.sites.push(site);
        }
    }

    UpdateSitesLocally(sites: Site[]): void {
        sites.map((x) => {
            if (this.model.sites.find((u) => u.id === x.id)) {
                let index = this.model.sites.findIndex((u) => u.id === x.id);
                this.model.sites[index] = x;
            }
            else {
                this.model.sites.push(x);
            }
        });
    }

    getSitesByAppId(currentApp: App): void {
        if (this.model.sitesLoading)
            return;

        this.model.sitesLoading = true;

        this._sitesDataService.getSitesByApp(currentApp)
            .subscribe({
                next: (sites: Site[]) => {
                    this.model.sites = sites;
                    this.setAdminSites();
                    this.setSites(sites);
                    this._snackBar.open(`${currentApp.aliases.site.plural} Loaded`, '', { duration: 2000 });
                },
                error: () => {
                    this.model.sites = [];
                    this.model.adminSites = [];
                    this.disconnectSites();
                    this._snackBar.open(`Error getting ${currentApp.aliases.site.plural.toLocaleLowerCase()}`, '', { duration: 2000 });
                },
                complete: () => { },
            })
            .add(() => {
                this.getActiveSitesCountByAppId(currentApp);
                this.model.sitesLoading = false;
            });
    }

    setAdminProjectsForAdminSites(adminProjects: Project[]): void {
        this.model.adminProjectsForSitesLogic = adminProjects;
    }

    setAdminSites(): void {
        if (!this.model.adminProjectsForSitesLogic) {
            this.model.adminSites = [];
            return;
        }
        this.model.adminSites = this.model.sites.filter((site: Site) =>
            site.projects?.some((projId: string) => this.model.adminProjectsForSitesLogic.find((proj: Project) => proj.id === projId))
        );
    }

    getSiteBySiteId(siteId: string): void {
        let site = this.model.sites.find((site) => site.id === siteId);
        this.model.currentSite = site ?? new Site();

        if (site) {
            this._siteReady.next(true);
            return;
        }

        this._siteReady.next(false);
    }

    getVisibleSites(app: App): void {
        if (!this.model.sitesLoading && this.model.sites.length === 0) {
            this.model.sitesLoading = true;
            this.model.currentAppId = app.id;

            this._sitesDataService.getVisibleSites(app)
                .subscribe({
                    next: (sites: Site[]) => {
                        this.model.sites = sites;
                        this.setSites(sites);
                    },
                    error: () => {
                        this.model.sites = new Array<Site>();
                        this._snackBar.open(`Error getting ${app.aliases.site.plural.toLowerCase()}`, '', { duration: 2000 });
                        this.disconnectSites();
                    },
                    complete: () => { },
                })
                .add(() => {
                    this.model.sitesLoading = false;
                });
        }
        else {
            setTimeout(() => {
                this.setSites(this.model.sites);
            }, 100);
        }
    }

    clearSites(): void {
        this.model.sites = [];
    }

    getSingleSiteBySiteId(app: App, siteId: string): void {
        this.model.siteLoading = true;

        this._sitesDataService.getSingleSite(app, siteId)
            .subscribe({
                next: (site: Site) => {
                    this.updateSiteLocally(site);
                    this.setSite(site);
                    this.setSites(this.model.sites);
                },
                error: () => { },
                complete: () => { },
            })
            .add(() => { this.model.siteLoading = false; });
    }

    getSingleSite(app: App, site: Site): void {
        if (site.id === '' || site === undefined)
            return;

        if (site.dateCreated) {
            this.setSite(site);
            this.setSites(this.model.sites);
            return;
        }

        this.model.siteLoading = true;

        this._sitesDataService.getSingleSite(app, site.id)
            .subscribe({
                next: (site: Site) => {
                    if (site.data)
                        this.mapDatesToDateObjectInGrids(site);
                    this.updateSiteLocally(site);
                    this.setSite(site);
                    this.setSites(this.model.sites);
                },
                error: () => { },
                complete: () => { },
            })
            .add(() => { this.model.siteLoading = false; });
    }

    siteSelectionChange(app: App, selectedSite: Site): void {
        if (!selectedSite) {
            this.disconnectSite();
            return;
        }
        this.getSingleSite(app, selectedSite);
    }

    mapDatesToDateObjectInGrids(site: Site): void {
        for (const dataItem in site.data) {
            if (Array.isArray(site.data[dataItem])) {
                site.data[dataItem].forEach((row: SiteFormGridRowItem) => {
                    if (row.id) {
                        this.mapDateObject(row);
                    }
                });
            }
        }
    }

    mapDateObject(row: SiteFormGridRowItem): void {
        for (const key in row) {
            if (row[key] && this.dateRegexPatternWithTime.test(row[key]) || this.dateRegexPattern.test(row[key])) {
                row[key] = this._dateConversionService.convertToDate(row[key]);
            }
        }
    }

    formatGridDatesToString(data): void {
        for (const dataItem in data) {
            if (Array.isArray(data[dataItem])) {
                data[dataItem].forEach((row: SiteFormGridRowItem) => {
                    if (row.id) {
                        this.mapDateToString(row);
                    }
                });
            }
        }
    }

    mapDateToString(row: SiteFormGridRowItem): void {
        for (const key in row) {

            if (moment.isMoment(row[key]) || row[key] instanceof Date) {
                row[key] = this._dateConversionService.convertDateToString(row[key]);
            }
        }
    }

    getActiveSitesCountByAppId(currentApp: App): void {
        this._sitesDataService.getActiveSitesCountByAppId(currentApp.id).subscribe({
            next: (count: number) => {
                currentApp.activeSitesCount = count;
                this._activeSitesCountReady.next(true);
            },
            error: () => { },
            complete: () => { },
        });
    }

    toggleActive(currentApp: App, site: Site, newActiveStatus: boolean, isAppLicensePerSite: boolean): void {
        site.active = newActiveStatus;
        if (isAppLicensePerSite) {
            if (site.renewSiteSubscription === undefined)
                site.renewSiteSubscription = false;

            if (site.active && (!site.renewCountDown || site.renewCountDown <= 0)) {
                const today = new Date();
                let newExpirationDate = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());

                site.renewDate = newExpirationDate.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" });
                site.renewCountDown = 365;
                site.renewSiteSubscription = true;
            }
        }

        site.activeIsLoading = true;

        this._sitesDataService
            .activateSite(currentApp, site)
            .subscribe({
                next: (site: Site) => {
                    this._snackBar.open(
                        `The ${currentApp.aliases.site.singular} '${site.siteName}' is now '${site.active ? 'active' : 'de-activated'}' `,
                        'Close',
                        { duration: 5000 }
                    );
                    if (!isAppLicensePerSite)
                        this.getActiveSitesCountByAppId(currentApp);
                },
                error: (error) => {
                    if (error.status === ClientErrorStatusCodes.Conflict) {
                        switch (error.error) {
                            case ErrorMessages.pastSiteMaxLimit:
                                this._snackBar.open(`Action disabled. You are over your active ${currentApp.aliases.site.singular} limit.`, 'Close', { duration: 5000 });
                                break;
                            case ErrorMessages.invalidChargeNumber:
                                this._snackBar.open(ErrorMessages.invalidChargeNumber, 'Close', { duration: 5000 });
                                break;
                            default:
                                this._snackBar.open('There was an error updating the record', 'Close', { duration: 5000 });
                        }
                    }
                    site.active = !site.active;
                },
                complete: () => { },
            })
            .add(() => {
                site.activeIsLoading = false;
            });
    }

    activateAllSites(currentApp: App, sitesAliasLowerCase: string): void {
        this.model.isSaving = true;
        this._sitesDataService.activateAllSites(currentApp).subscribe({
            next: () => {
                this.model.isSaving = false;
                this.getSitesByAppId(currentApp);
                this._snackBar.open(`All the ${sitesAliasLowerCase} have been activated`, "Close", { duration: 5000 });
                if (currentApp.activeLicenseTier.id !== LicenseTiers.PerSite)
                    this.getActiveSitesCountByAppId(currentApp);
            },
            error: () => {
                this.model.isSaving = false;
                this._snackBar.open(`Error activating all ${sitesAliasLowerCase}`);

            },
            complete: () => { },
        })
            .add(() => {
            });
    }
}
