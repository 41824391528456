import { Injectable } from '@angular/core';

import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class DateConversionService {
    constructor() { }

    convertToDate(stringDate: string): Date {
        return moment(stringDate).utc().toDate();
    }

    convertDateToString(dateObj: Date | string): string {
        if (dateObj === null || dateObj === undefined || dateObj === '')
            return '';

        return moment(dateObj).utc().format("YYYY-MM-DD");
    }
}
