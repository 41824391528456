import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { App, Project } from '../../models';
import { ReportDefinition } from '../../models/report-definition';
import { ReportsDefinitionDataService } from '../data-services';

@Injectable({
    providedIn: 'root',
})
export class ReportDefinitionsLogicService {
    private _reportDefinitions: ReportDefinition[] = [];

    reportDefinitions: BehaviorSubject<ReportDefinition[]> = new BehaviorSubject<ReportDefinition[]>(null);
    reportDefinition: BehaviorSubject<ReportDefinition> = new BehaviorSubject<ReportDefinition>(null);
    newReportDefinition: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    reportDefinitionLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    currentReportDefinition: ReportDefinition;
    currentAppReportDefinition: ReportDefinition;

    constructor(
        private _snackBar: MatSnackBar,
        private _reportDefinitionData: ReportsDefinitionDataService,
    ) {
        if (sessionStorage.getItem('reportDefinition')) {
            let reportDefinition = JSON.parse(sessionStorage.getItem('reportDefinition'));
            this.setReportDefinition(reportDefinition);
        }
    }

    setReportDefinition(reportDefinition: ReportDefinition): void {
        this.setCurrentReportDefinition(reportDefinition);
        sessionStorage.setItem('reportDefinition', JSON.stringify(reportDefinition));
    }

    private setCurrentReportDefinition(reportDefinition: ReportDefinition): void {
        this.reportDefinition.next(reportDefinition);
        this.currentReportDefinition = reportDefinition;
    }

    resetReportDefinitions(): void {
        this.reportDefinitions.next(this._reportDefinitions);
    }

    setReportDefinitions(reportDefinitions: any): void {
        sessionStorage.setItem('reportDefinitions', JSON.stringify(reportDefinitions));
        this.reportDefinitions.next(reportDefinitions);
    }

    updateReportDefinition(app: App, project: Project, reportDefinition: ReportDefinition): Observable<any> {
        const result$ = new Subject<any>();

        this._reportDefinitionData.updateReportDefinition(app, project, reportDefinition)
            .subscribe({
                next: (response: any) => {
                    this.updateReportDefinitionLocally(response);
                    result$.next(response);
                    result$.complete();
                }
            });

        return result$.asObservable();
    }

    removeReportDefinitionFromProject(reportDefinitionId: string, app: App): Observable<any> {
        const result$ = new Subject<any>();

        this._reportDefinitionData.removeReportDefinitionFromProject(reportDefinitionId, app)
            .subscribe({
                next: (response: any) => {
                    this.updateReportDefinitionLocally(response);
                    result$.next(response);
                    result$.complete();
                }
            });

        return result$.asObservable();
    }

    getReportDefinitionsByProjId(app: App, project: Project): void {
        if (!project.id)
            return;

        this.reportDefinitionLoading.next(true);

        this._reportDefinitionData.getReportDefinitionsByProjId(app, project)
            .subscribe({
                next: (response: any) => {
                    this._reportDefinitions = response;
                    this.setReportDefinitions(response);
                },
                error: () => {
                    this._reportDefinitions = [];
                    this.disconnectReportDefinition();
                    this._snackBar.open('Error getting Report Definitions', '', {
                        duration: 2000,
                    });
                }
            })
            .add(() => {
                this.reportDefinitionLoading.next(false);
            });
    }

    updateReportDefinitionLocally(reportDefinition: ReportDefinition): void {
        if (this._reportDefinitions.find((u) => u.id === reportDefinition.id)) {
            let index = this._reportDefinitions.findIndex((u) => u.id === reportDefinition.id);
            this._reportDefinitions[index] = reportDefinition;
        }
        else {
            this._reportDefinitions.push(reportDefinition);
        }
        this.resetReportDefinitions();
    }

    disconnectReportDefinition(): void {
        this.setCurrentReportDefinition(null);
        sessionStorage.removeItem('reportDefinition');
    }

}
