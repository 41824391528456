import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { BehaviorSubject, Subject } from 'rxjs';

import { App, Notification, Project } from '../../models';
import { NotificationDataService } from '../data-services';

@Injectable({
    providedIn: 'root'
})
export class NotificationsLogicService {
    private _notificationLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _notificationsLoaded: Subject<boolean> = new Subject<boolean>();

    notificationLoading$ = this._notificationLoading.asObservable();
    notificationsLoaded$ = this._notificationsLoaded.asObservable();

    notifications: Notification[] = [];
    currentNotification: Notification;
    currentAppNotification: Notification;
    isSaving = false;

    constructor(
        private _snackBar: MatSnackBar,
        private _notificationData: NotificationDataService,
    ) {
        if (sessionStorage.getItem("notification")) {
            let notification = JSON.parse(sessionStorage.getItem("notification"));
            this.setNotification(notification);
        }
    }

    setNotification(notification: Notification): void {
        this.currentNotification = notification;
        sessionStorage.setItem("notification", JSON.stringify(notification));
    }

    setNotifications(notifications: any): void {
        sessionStorage.setItem('notifications', JSON.stringify(notifications));
    }

    addNotification(app: App, project: Project, notification: Notification): void {
        this.isSaving = true;

        this._notificationData.updateNotification(app, project, notification)
            .subscribe({
                next: (result: Notification) => {
                    this.updateNotificationLocally(result);

                    this._snackBar.open(`Notification saved`, `Close`, { duration: 5000 });
                }
            })
            .add(() => {
                this._notificationsLoaded.next(true);
                this.isSaving = false;
            });
    }

    getNotificationsByProjId(app: App, project: Project): void {
        if (!project.id)
            return;

        this._notificationLoading.next(true);

        this._notificationData.getNotificationsByProjId(app, project)
            .subscribe({
                next: (response: Notification[]) => {
                    this.notifications = response;
                    this.setNotifications(response);
                    this._notificationsLoaded.next(true);
                },
                error: () => {
                    this.notifications = [];
                    this._snackBar.open(`Error getting notifications`, `Close`, {
                        duration: 2000,
                    });
                }
            })
            .add(() => {
                this._notificationLoading.next(false);
            });
    }

    deleteNotification(app: App, notification: Notification): void {
        this.isSaving = true;

        this._notificationData.deleteNotification(app, notification)
            .subscribe({
                next: () => {
                    const index = this.notifications.findIndex((value: Notification) => value.id === notification.id);
                    this.notifications.splice(index, 1);
                    this._snackBar.open(`Notification deleted.`, 'Close', { duration: 5000 });
                },
                error: () => {
                    this._snackBar.open('Unable to delete notification.');
                }
            })
            .add(() => {
                this._notificationsLoaded.next(true);
                this.isSaving = false;
            });
    }

    editNotification(app: App, project: Project, notification: Notification): void {
        this.isSaving = true;

        this._notificationData.updateNotification(app, project, notification)
            .subscribe({
                next: (result: Notification) => {
                    this.updateNotificationLocally(result);
                    this._snackBar.open(`Notification saved`, 'Close', { duration: 5000 });
                },
                error: () => {
                    this._snackBar.open('Error saving notifications');
                }
            })
            .add(() => {
                this._notificationsLoaded.next(true);
                this.isSaving = false;
            });
    }

    updateNotificationLocally(notification: Notification): void {
        if (this.notifications.find(u => u.id === notification.id)) {
            const index = this.notifications.findIndex(u => u.id === notification.id);
            this.notifications[index] = notification;
        }
        else {
            this.notifications.push(notification);
        }
    }

    changeActive(app: App, project: Project, notification: Notification): void {
        this.isSaving = true;

        this._notificationData.updateNotification(app, project, notification)
            .subscribe({
                next: (response: Notification) => {
                    this._snackBar.open(`The Notification '${notification.notificationName}' is now ${notification.active ? 'active' : 'de-activated'}`, `Close`, { duration: 5000 });

                    this.updateNotificationLocally(response);
                },
                error: () => {
                    notification.active = !notification.active;

                    this._snackBar.open(`There was an error updating the record`, `Close`, { duration: 5000 });
                }
            })
            .add(() => {
                this._notificationsLoaded.next(true);
                this.isSaving = false;
            });
    }

}
