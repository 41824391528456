import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NavigationUtilityService {

    private _isExpanded = new Subject<boolean>();
    isExpanded$ = this._isExpanded.asObservable();

    isExpanded = false;

    constructor() { }

    init(): void {
        this.isExpanded = localStorage.getItem('smarts-nav-menu-expanded') === 'true';
    }

    expandCollapseNavMenu(): void {
        this.setIsExpanded();
        localStorage.setItem('smarts-nav-menu-expanded', JSON.stringify(this.isExpanded));
    }

    setIsExpanded(): void {
        this.isExpanded = !this.isExpanded;
        this._isExpanded.next(true);
    }
}
