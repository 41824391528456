import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { RequestAppAccessDataService } from '../data-services';

@Injectable({
    providedIn: 'root'
})
export class RequestAppAccessLogicService {

    constructor(
        private _requestAppData: RequestAppAccessDataService
    ) { }

    requestNewAppAccess(name: string, appName: string): Observable<any> {
        const result$ = new Subject<any>();

        const appRequestForm = { name: name, appName: appName };

        this._requestAppData.requestNewAppAccess(name, appRequestForm)
            .subscribe({
                next: (response: any) => {
                    result$.next(response);
                    result$.complete();
                },
                error: (error: any) => {
                    result$.error(error);
                }
            });

        return result$.asObservable();
    }

}
