/* istanbul ignore file */
export enum FieldTypes {
    TextBox = 'Text Box',
    TextArea = 'Text Area',
    DateSelect = 'Date Select',
    CheckBox = 'Checkbox',
    DropDown = 'Drop Down List',
    PhotoGallery = 'Photo Gallery',
    Uploader = 'Uploader',
    Grid = 'Grid',
    Number = 'Number',
    ReadOnlyText = 'Read Only Text',
    ReadOnlyTextArea = 'Read Only Text Area',
    FileUpload = 'File Upload',
    TrueFalse = 'True/False',
    URLHyperlink = 'Web Link',
    EmailHyperlink = 'Email',
    Person = 'Person'
}

export enum GridFieldTypes {
    DateSelect = 'Date Select',
    Number = 'Number',
    TrueFalse = 'True/False',
    DropDown = 'Drop Down List',
    Text = 'Text',
    TextArea = 'Text Area',
    FileUpload = 'File Upload',
    URLHyperlink = 'Web Link',
    EmailHyperlink = 'Email',
    Person = 'Person'
}
