import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpBaseService } from '../../../core';
import { ReleaseSummary } from '../../models';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
/* istanbul ignore file */
export class ReleaseSummaryDataService extends HttpBaseService {

    private route = environment.api + 'release-summary/';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    getReleaseSummary(): Observable<ReleaseSummary> {
        const url = `${this.route}`;
        return this.httpGet(url);
    }
}
