<h1
    mat-dialog-title
    class="khts-mat-dialog-title"
>
    {{ siteAlias }} Upload Error
</h1>

<div
    mat-dialog-content
    class="khts-mat-dialog-content"
>
    <p>
        We found the following error(s) in your Excel spreadsheet and were unable to upload your
        {{ sitesAliasLowerCase }}. Please correct the error(s) and reupload the file to proceed.
    </p>

    <div
        class="kh-sites-error-alert"
        *ngIf="data.missingSiteNumbers"
    >
        <p class="kh-error-dialog kh-error-with-icon">
            <span class="material-icons kh-error-icon"> error </span>
            <span class="kh-bold-text">Missing {{ siteAliasLowerCase }} numbers. </span>
            <span class="kh-flex-grow-shrink">
                &nbsp; Ensure each {{ siteAliasLowerCase }} has a corresponding unique
                {{ siteAliasLowerCase }} number.</span
            >
        </p>
    </div>

    <div
        class="kh-sites-error-alert"
        *ngIf="data.duplicateSiteNumbers.length > 0"
    >
        <p class="kh-error-dialog kh-error-with-icon">
            <span class="material-icons kh-error-icon"> error </span>
            <span class="kh-bold-text">Duplicate {{ siteAliasLowerCase }} numbers. &nbsp;</span
            ><span class="kh-flex-grow-shrink">
                Remove or correct the following duplicate {{ siteAliasLowerCase }} numbers:
                {{ data.duplicateSiteNumbers.join(', ') }}</span
            >
        </p>
    </div>

    <div
        class="kh-sites-error-alert"
        *ngIf="data.missingSiteNames"
    >
        <p class="kh-error-dialog kh-error-with-icon">
            <span class="material-icons kh-error-icon"> error </span>
            <span class="kh-bold-text">Missing {{ siteAliasLowerCase }} names.</span>
            <span class="kh-flex-grow-shrink">
                &nbsp; Ensure each {{ siteAliasLowerCase }} has a corresponding {{ siteAliasLowerCase }} name.</span
            >
        </p>
    </div>

    <div
        class="kh-sites-error-alert"
        *ngIf="data.missingAddress"
    >
        <p class="kh-error-dialog kh-error-with-icon">
            <span class="material-icons kh-error-icon"> error </span>
            <span class="kh-bold-text">Missing address details.</span>
            <span class="kh-flex-grow-shrink">
                &nbsp; Ensure each {{ siteAliasLowerCase }} has a complete address, including the street number and
                name, city, state and zip code.</span
            >
        </p>
    </div>

    <div
        class="kh-sites-error-alert"
        *ngIf="data.invalidAddresses.length > 0"
    >
        <div class="kh-error-dialog kh-error-with-icon">
            <span class="material-icons kh-error-icon"> error </span>
            <span class="kh-bold-text">Invalid addresses.</span>
            <span class="kh-flex-grow-shrink"> &nbsp; Correct the following invalid addresses:</span>
        </div>
        <div class="kh-address-list">
            <ul>
                <li *ngFor="let address of data.invalidAddresses">
                    {{ address.addressName }} {{ address.city }}, {{ address.state }} {{ address.zipCode }}
                </li>
            </ul>
        </div>
    </div>
</div>
<div
    mat-dialog-actions
    class="khts-mat-dialog-actions"
    align="end"
>
    <app-secondary-button (onClick)="openFeedbackForm()">
        <span class="material-icons"> help </span>
        Get help
    </app-secondary-button>

    <app-flat-button mat-dialog-close>Close</app-flat-button>
</div>
<kh-close-dialog-button (click)="closeDialog()"></kh-close-dialog-button>
