import { Injectable } from '@angular/core';
import { Project } from '../../models';
import { Column, Field, Section } from './models';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbLogicService {

    private breadcrumbs: string[];

    constructor() { }

    createBreadcrumbs(project: Project): void {
        this.breadcrumbs = new Array<string>();

        project.JSONForms?.Sections?.forEach((section: Section) => {
            this.sectionObject(section);
        });
    }

    sectionsArray(sections: Section[]): void {
        sections.forEach((section: Section) => {
            this.breadcrumbs.push(section.SectionTitle);

            if (section.Columns?.length)
                this.columnsArray(section.Columns);

            if (section.Sections?.length) {
                this.sectionsArray(section.Sections);
            }

            this.breadcrumbs.pop();
        });
    }

    sectionObject(section: Section): void {
        this.breadcrumbs.push(section.SectionTitle);

        if (section.Columns?.length)
            this.columnsArray(section.Columns);

        if (section.Sections?.length)
            this.sectionsArray(section.Sections);

        this.breadcrumbs.pop();
    }

    columnsArray(columns: Column[]): void {
        columns.forEach((column: Column) => {

            if (column.type === 'group')
                this.columnGroup(column);
            else
                this.columnObject(column);

        });
    }

    columnGroup(column: Column): void {
        this.breadcrumbs.push((column.GroupTitle) ? column.GroupTitle : `Unnamed Column`);
        this.columnObject(column);
        this.breadcrumbs.pop();
    }

    columnObject(column: Column): void {
        if (column.Fields?.length)
            this.fieldsArray(column.Fields);
    }

    fieldsArray(fields: Field[]): void {
        fields.forEach((field: Field) => {
            this.updateFieldWithCurrentBreadcrumbs(field);
        });
    }

    updateFieldWithCurrentBreadcrumbs(field: Field): void {
        let crumbs = '';

        this.breadcrumbs.forEach((breadcrumb: string) => {
            crumbs += `${breadcrumb} > `;
        });
        field.breadcrumb = crumbs;

        field.parent = `... > ${this.breadcrumbs[this.breadcrumbs.length - 1]}`;
    }
}
