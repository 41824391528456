import { Injectable } from '@angular/core';

import { Project } from '../../models';
import { FieldType, FieldTypePrimitive, ReportFilterType } from '../../shared/enums';
import { FieldTypes } from './enums/field-types';
import { Column, Field, FieldProperty, Options, Section } from './models';

@Injectable({
    providedIn: 'root'
})
export class FieldsLogicService {

    listOfFields: Field[];
    gridFieldsToFields: Field[];
    listOfFieldsAndReadOnlyFields: Field[];

    constructor() { }

    getBreadCrumbPathByFieldId(fieldId: string): string | undefined {
        const field = this.listOfFields.find(field => field.id === fieldId);
        return field ? field.breadcrumb : undefined;
    }

    getFieldTypeByFieldId(fieldId: string): string | undefined {
        const field = this.listOfFields.find(field => field.id === fieldId);
        return field ? field.FieldType.text : undefined;
    }

    getAllFields(project: Project): Field[] {
        this.commonLogic(project);
        return this.listOfFields;
    }

    getAllNonGridFields(project: Project): Field[] {
        this.commonLogic(project);
        return this.listOfFields.filter((field: Field) => field?.FieldType?.id !== FieldType.Grid);
    }

    getAllFieldsAndReadOnlyFields(project: Project): Field[] {
        this.commonLogic(project);
        return this.listOfFieldsAndReadOnlyFields;
    }

    getGridFieldsToFields(project: Project): Field[] {
        this.commonLogic(project);
        return this.gridFieldsToFields;
    }

    commonLogic(project: Project): void {
        this.listOfFields = new Array<Field>();
        this.listOfFieldsAndReadOnlyFields = new Array<Field>();
        this.gridFieldsToFields = new Array<Field>();

        project.JSONForms?.Sections?.forEach((section: Section) => {
            this.sectionObject(section);
        });

        this.listOfFields.sort((a, b) => (a.Name > b.Name) ? 1 : -1);
        this.listOfFieldsAndReadOnlyFields = this.listOfFields;
        this.listOfFields = this.listOfFields.filter((field: Field) => !field.FieldType?.options?.readOnly);

        this.gridFieldsToFields = this.gridFieldsToFields.filter((field: Field) => !field.FieldType?.options?.readOnly);
        this.gridFieldsToFields.sort((a, b) => (a.Name > b.Name) ? 1 : -1);
    }

    getAllStringNumberAndDateFields(project: Project): Field[] {
        this.listOfFields = new Array<Field>();
        this.gridFieldsToFields = new Array<Field>();

        project.JSONForms.Sections?.forEach((section: Section) => {
            this.sectionObject(section);
        });

        this.listOfFields = this.listOfFields.filter((field: Field) => {
            return (!field.FieldType?.options?.readOnly && (field.FieldType.type === "number" || field.FieldType.type === "string"));
        });
        this.listOfFields.sort((a, b) => (a.Name > b.Name) ? 1 : -1);
        return this.listOfFields;
    }

    sectionsArray(sections: Section[]): void {
        sections.forEach((section: Section) => {
            if (section.Columns?.length)
                this.columnsArray(section.Columns);

            if (section.Sections?.length)
                this.sectionsArray(section.Sections);
        });
    }

    sectionObject(section: Section): void {
        if (section.Columns?.length)
            this.columnsArray(section.Columns);

        if (section.Sections?.length)
            this.sectionsArray(section.Sections);
    }

    columnsArray(columns: Column[]): void {
        columns.forEach((column: Column) => {
            this.columnObject(column);
        });
    }

    columnObject(column: Column): void {
        if (column.Fields?.length)
            this.fieldsArray(column.Fields);
    }

    fieldsArray(fields: Field[]): void {
        fields.forEach((field: Field) => {
            if (field.SelectOption === FieldType.Grid)
                this.convertGridColumnsToFields(field);
            else
                this.gridFieldsToFields.push(field);

            this.listOfFields.push(field);
        });
    }

    setDefaultReportFilterType(field: Field): void {
        if (!field.reportFilterType)
            field.reportFilterType = ReportFilterType.StringSearch;
    }

    convertGridColumnsToFields(field: Field): void {
        field.FieldType.options.columns.forEach((column: Column) => {
            const newField = new Field();

            newField.dataId = field.id;
            newField.id = column.id;
            newField.Name = column.header;
            newField.SelectOption = this.getFieldTypeIdAndSelectOption(column);
            newField.FieldType = new FieldProperty();
            newField.FieldType.id = this.getFieldTypeIdAndSelectOption(column);
            newField.FieldType.options = this.getOptions(column);
            newField.FieldType.text = this.getTextType(column);
            newField.FieldType.required = field.FieldType.required;
            newField.FieldType.type = this.getFieldTypePrimitive(column);
            newField.FieldType.upload = this.getFieldTypeUpload(column);
            newField.FieldType.enum = this.getFieldTypeEnumValues(column);
            newField.breadcrumb = `${field.breadcrumb} ${field.Name} >`;
            newField.parent = `... > ${field.Name}`;

            this.gridFieldsToFields.push(newField);
        });
    }

    getFieldTypeIdAndSelectOption(column: Column): FieldType | undefined {
        if (column.type === "Text")
            return FieldType.TextBox;

        if (column.type === FieldTypes.TextArea)
            return FieldType.TextArea;

        if (column.type === FieldTypes.DateSelect)
            return FieldType.DateSelect;

        if (column.type === FieldTypes.Number)
            return FieldType.Number;

        if (column.type === FieldTypes.FileUpload)
            return FieldType.Uploader;

        if (column.type === FieldTypes.TrueFalse)
            return FieldType.CheckBox;

        if (column.type === FieldTypes.DropDown)
            return FieldType.DropDown;

        if (column.type === FieldTypes.EmailHyperlink)
            return FieldType.EmailHyperlink;

        if (column.type === FieldTypes.URLHyperlink)
            return FieldType.URLHyperlink;

        if (column.type === FieldTypes.Person)
            return FieldType.Person;
    }

    getOptions(column: Column): Options | undefined {
        if (column.type === FieldTypes.TextArea) {
            const options = new Options();
            options.multi = true;
            return options;
        }

        if (column.type === FieldTypes.DateSelect) {
            const options = new Options();
            options.dateFormat = "MM/DD/YYYY";
            options.dateSaveFormat = "YYYY-MM-DD";
            options.format = "date";
            return options;
        }

        if (column.type === FieldTypes.FileUpload) {
            const options = new Options();
            options.allowedExtensions = [];
            options.maxFileSize = 100000000;
            return options;
        }

        if (column.type === FieldTypes.TrueFalse) {
            const options = new Options();
            options.toggle = true;
            return options;
        }
    }

    getTextType(column: Column): string | undefined {
        if (column.type === "Text")
            return FieldTypes.TextBox;

        if (column.type === FieldTypes.TextArea)
            return FieldTypes.TextArea;

        if (column.type === FieldTypes.DateSelect)
            return FieldTypes.DateSelect;

        if (column.type === FieldTypes.Number)
            return FieldTypes.Number;

        if (column.type === FieldTypes.FileUpload)
            return FieldTypes.Uploader;

        if (column.type === FieldTypes.TrueFalse)
            return FieldTypes.CheckBox;

        if (column.type === FieldTypes.DropDown)
            return FieldTypes.DropDown;

        if (column.type === FieldTypes.EmailHyperlink)
            return FieldTypes.EmailHyperlink;

        if (column.type === FieldTypes.URLHyperlink)
            return FieldTypes.URLHyperlink;

        if (column.type === FieldTypes.Person)
            return FieldTypes.Person;
    }

    getFieldTypePrimitive(column: Column): string | undefined {
        if (column.type === "Text")
            return FieldTypePrimitive.String;

        if (column.type === FieldTypes.TextArea)
            return FieldTypePrimitive.String;

        if (column.type === FieldTypes.DateSelect)
            return FieldTypePrimitive.String;

        if (column.type === FieldTypes.Number)
            return FieldTypePrimitive.Number;

        if (column.type === FieldTypes.TrueFalse)
            return FieldTypePrimitive.Boolean;

        if (column.type === FieldTypes.DropDown)
            return FieldTypePrimitive.String;

        if (column.type === FieldTypes.EmailHyperlink)
            return FieldTypePrimitive.String;

        if (column.type === FieldTypes.URLHyperlink)
            return FieldTypePrimitive.String;
    }

    getFieldTypeUpload(column: Column): boolean | undefined {
        if (column.type === FieldTypes.FileUpload)
            return true;
    }

    getFieldTypeEnumValues(column: Column): string[] | undefined {
        if (column.type === FieldTypes.DropDown)
            return column.enum;
    }
}
