import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { App } from '../../models/app';
import { RequestAccessDataService } from '../data-services';

@Injectable({
    providedIn: 'root'
})
export class RequestAccessLogicService {

    constructor(
        private _requestAccessData: RequestAccessDataService
    ) { }

    requestAccess(firstName: string, lastName: string, email: string, cellPhone: string, clientName: string): Observable<App> {
        const requestForm = { firstName: firstName, lastName: lastName, email: email, cellPhone: cellPhone, clientName: clientName };

        const result$ = new Subject<App>();

        this._requestAccessData.requestAccess(requestForm)
            .subscribe({
                next: (response: App) => {
                    result$.next(response);
                    result$.complete();
                }
            });

        return result$.asObservable();
    }

}
