import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { FileInfo } from '@progress/kendo-angular-upload';

import { HelpFeedbackServiceModel, ServiceRequestResponse, User } from '../../models';
import { HelpFeedbackDataService } from './../data-services/help-feedback-data.service';

@Injectable({
    providedIn: 'root'
})
export class HelpFeedbackLogicService {

    model: HelpFeedbackServiceModel = new HelpFeedbackServiceModel();

    constructor(
        private _helpFeedbackDataService: HelpFeedbackDataService
    ) { }

    initModel(): void {
        if (this.model === undefined) {
            this.model = new HelpFeedbackServiceModel();
        }
    }

    createFormGroup(feedBackForm: FormGroup): FormGroup {
        feedBackForm = new FormGroup({
            description: new FormControl(null, [Validators.required]),
            contactMethod: new FormControl(null, [Validators.required]),
            phone: new FormControl(null, [Validators.required])
        });
        return feedBackForm;
    }

    createFormData(feedBackForm: FormGroup, files: FileInfo[], user: User): FormData {
        let feedBackData = new FormData();
        feedBackData.append('description', (feedBackForm.value.description));
        feedBackData.append('firstName', (user.firstName));
        feedBackData.append('lastName', (user.lastName));
        feedBackData.append('userEmail', (user.email));
        feedBackData.append('callBackNumber', (feedBackForm.value.phone));
        feedBackData.append('application', "Smarts");
        feedBackData.append('contactMethod', (feedBackForm.value.contactMethod));

        if (files.length)
            feedBackData.append('files', files[0].rawFile);

        return feedBackData;
    }

    createServiceRequest(feedBackData): void {
        this.setLoadingStatus();

        this._helpFeedbackDataService.postFormData(feedBackData)
            .subscribe({
                next: (serviceResponse: ServiceRequestResponse) => {
                    this.model.serviceResponse = serviceResponse;
                },
                complete: () => {
                    this.setLoadingStatus();
                }
            });
    }

    setLoadingStatus(): void {
        this.model.responseLoading = !this.model.responseLoading;
    }

}
