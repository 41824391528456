import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from 'src/auth/pages/404/error-page.component';
import { AuthGuard } from '../app/guards/auth.guard';

const routes: Routes = [
    {
        path: 'home',
        loadChildren: () => import('../auth/modules/home/home.module').then(m => m.HomeModule)
    },
    {
        path: '',
        loadChildren: () => import('./newnav/newnav.module').then(m => m.NewnavModule),
        canActivate: [AuthGuard]
    },
    {
        path: '**',
        pathMatch: 'full',
        component: ErrorPageComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes,
        {
            useHash: false,
            // enableTracing: true
        }
    )
    ],
    exports: [RouterModule]
})
export class MainRoutingModule { }
