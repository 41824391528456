import { Color } from '@angular-material-components/color-picker';
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { App } from '../../models';
import { KhThemeServiceModel } from '../../models/kh-theme-service-model';
import { MapIcon } from '../../models/map-pin-model';
import { CSSCalculatedVariables, MapPinStyles } from '../../shared/enums';
import { MapSettingsDataService } from '../data-services/map-settings-data.service';

@Injectable({
    providedIn: 'root'
})
export class KHThemeService {

    private _root = document.documentElement;

    model: KhThemeServiceModel;

    contentHeight: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    color: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    name: string = environment.name;
    primaryColor: string = environment.colors.primaryColor;
    primaryTextColor: string = environment.colors.primaryTextColor;
    primaryBlackColor: string = environment.colors.primaryBlackColor;
    primaryLogo: string = environment.logos.primaryLogo;
    secondaryColor: string = environment.colors.secondaryColor;
    secondaryTextColor: string = environment.colors.secondaryTextColor;
    secondaryLogo: string = environment.logos.secondaryLogo;
    mapPin: string = null;
    mapPinHeight: number;
    mapPinStyle: number = MapPinStyles.Default;
    mapPinWidth: number;
    mapPinColor: Color;
    padding = 156;
    gridHeight = 0;
    svg = 'assets/icons/Pin.svg';
    svgDocument: Document;
    lightTextColor = new Color(255, 255, 255, 1);
    darkTextColor = new Color(21, 24, 41, 1);
    defaultTextColor = this.lightTextColor;

    constructor(
        private _mapSettingsDataService: MapSettingsDataService
    ) {
        this.initModel();
        this.resetTheme();
        this.calculateContentHeight();
    }

    initModel(): void {
        if (this.model === undefined) this.model = new KhThemeServiceModel();
    }

    setTheme(app: App): void {
        this.name = app.name;
        this._updateFile(app, 'primaryLogo');
        this._updateFile(app, 'secondaryLogo');
        this._updateFile(app, 'mapPin');
        this._updateThemeColor(app, "primaryColor");
        this.primaryTextColor = this.getPrimaryTextColorFormattedAsHexStringWithHashtagForApp(app);
        this._updateThemeColor(app, "secondaryColor");
        this._updateThemeColor(app, "secondaryTextColor");
        this.mapPinHeight = app.mapPinHeight ?? 25;
        this.mapPinWidth = app.mapPinWidth ?? 25;
        this.mapPinStyle = app.mapPinStyle ?? MapPinStyles.Default;
        this.mapPinColor = app.mapPinColor ?? new Color(234, 67, 53, 255);
        this.color.next(this.primaryColor);
        this.setMapIcon();

        this.setSCSSVariables();

        this._mapSettingsDataService.loadSVG()
            .then(svgDocument => {
                this.svgDocument = svgDocument;
                this.svg = this.colorizeSVG();
                this.setMapIcon();
            });
    }

    private _updateFile(app: App, field: string): void {
        if (app[field] === undefined || app[field] === null) {
            this[field] = null;
        }
        else {
            this[field] = app[field].uri;
        }
    }

    private _updateThemeColor(app: App, field: string): void {
        if (app !== null && app[field] !== undefined) {
            this[field] = "#" + app[field].hex;
        }
        else {
            this[field] = environment.colors[field];
        }
    }

    resetTheme(): void {
        this.name = environment.name;
        this.primaryColor = environment.colors.primaryColor;
        this.primaryTextColor = environment.colors.primaryTextColor;
        this.primaryLogo = environment.url + environment.logos.primaryLogo;
        this.secondaryColor = environment.colors.secondaryColor;
        this.secondaryTextColor = environment.colors.secondaryTextColor;
        this.secondaryLogo = environment.url + environment.logos.secondaryLogo;
        this.mapPinHeight = 25;
        this.mapPinWidth = 25;
        this.mapPinStyle = MapPinStyles.Default;
        this.mapPinColor = new Color(234, 67, 53, 255);
        this.mapPin = null;
        this.color.next(environment.colors.primaryColor);
        this.resetMapIcon();

        this.setSCSSVariables();
    }

    getPrimaryTextColorFormattedAsHexStringWithHashtagForApp(app: App): string {
        let primaryColorObject = app.primaryColor;
        if (!primaryColorObject)
            primaryColorObject = this.hexToColorObject(environment.colors.primaryColor);
        const primaryTextColorObject = this.calculateTextColorForBgColor(primaryColorObject);
        let hex = primaryTextColorObject.hex;
        if (!hex)
            hex = this.defaultTextColor.hex;
        return '#' + hex;
    }

    isValidHexString(hex: string): boolean {
        return /^#([0-9a-f]{3}){1,2}$/i.test(hex);
    }

    hexToColorObject(hexString: string): Color {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexString);
        return result ? new Color(
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16)
        ) : null;
    }

    calculateContentHeight(padding = 156): void {
        this.gridHeight = window.innerHeight - 290;
        let paddedHeight = environment.outerHeight + padding;
        if (window.innerHeight <= paddedHeight) {
            this.contentHeight.next(paddedHeight + 20);
        }
        else {
            this.contentHeight.next(window.innerHeight - environment.outerHeight + 20);
        }
    }

    setMapIcon(): void {
        const scaledSize = new google.maps.Size(this.mapPinWidth, this.mapPinHeight);

        if (this.mapPinStyle === MapPinStyles.Default || !this.mapPin) {
            this.model.mapIcon = new MapIcon(this.svg, scaledSize);
            return;
        }
        this.model.mapIcon = new MapIcon(this.mapPin, scaledSize);
    }

    resetMapIcon(): void {
        if (this.model) {
            this.model.mapIcon = new MapIcon("assets/icons/Pin.svg", new google.maps.Size(1, 1));
        }
    }

    colorizeSVG(): string {
        if (!this.svgDocument) {
            return this.svg;
        }

        const color: string = this.mapPinColor.rgba;
        const styleElement = this.svgDocument.querySelector('style');

        if (styleElement) {
            styleElement.textContent = `.icon-fill { fill: ${color}; }`;
        }

        const svgString = new XMLSerializer().serializeToString(this.svgDocument.documentElement);
        return `data:image/svg+xml;utf8,${encodeURIComponent(svgString)}`;
    }

    calculateLuminanceOfColorFast(rgb: Color): number {
        return (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;
    }

    private sRGBtoLinear(colorChannel: number): number {
        // Send this function a decimal sRGB gamma encoded color value
        // between 0.0 and 1.0, and it returns a linearized value.
        const gamma = 2.4;
        if (colorChannel <= 0.04045)
            return colorChannel / 12.92;
        return Math.pow(((colorChannel + 0.055) / 1.055), gamma);
    }

    calculateLuminanceOfColor(color: Color): number {
        const vR = color.r / 255;
        const vG = color.g / 255;
        const vB = color.b / 255;
        return (0.2126 * this.sRGBtoLinear(vR) + 0.7152 * this.sRGBtoLinear(vG) + 0.0722 * this.sRGBtoLinear(vB));
    }

    private calculatePerceivedLightnessOfLuminance(luminance): number {
        // Send this function a luminance value between 0.0 and 1.0,
        // and it returns perceptual lightness
        if (luminance <= (216 / 24389))        // The CIE standard states 0.008856 but 216/24389 is the intent for 0.008856451679036
            return luminance * (24389 / 27);  // The CIE standard states 903.3, but 24389/27 is the intent, making 903.296296296296296
        return Math.pow(luminance, (1 / 3)) * 116 - 16;
    }

    calculatePerceivedLightnessOfColor(color: Color): number {
        const luminance = this.calculateLuminanceOfColor(color);
        return this.calculatePerceivedLightnessOfLuminance(luminance);
    }

    calculateTextColorForBgColor(bgColor: Color): Color {
        const bgPerceivedLightness = this.calculatePerceivedLightnessOfColor(bgColor);
        const maxBgPerceivedLightnessForLightText = 68.5;
        if (bgPerceivedLightness > maxBgPerceivedLightnessForLightText)
            return this.darkTextColor;
        return this.lightTextColor;
    }

    setSCSSVariables(): void {
        this._root.style.setProperty(CSSCalculatedVariables.ThemeColor, this.primaryColor);
        this._root.style.setProperty(CSSCalculatedVariables.ThemeColorFiftyOpacity, this.primaryFiftyOpacity());
        this._root.style.setProperty(CSSCalculatedVariables.ThemeColorSevenOpacity, this.primarySevenOpacity());
    }

    primaryFiftyOpacity(): string {
        return this.primaryColor + '50';
    }

    primarySevenOpacity(): string {
        return this.primaryColor + '07';
    }
}

